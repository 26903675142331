footer {
  min-height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;

  // li{
  //     display: inline-flex;

  //     a{
  //         align-items: center;
  //     }
  img {
    width: 4vw;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    @media screen and (min-width: 1400px) {
      width: 4rem;
    }
  }
}
