#contactLinks {
  a {
    text-decoration: none;
    // padding: 10px;
    z-index: 50;
    li {
      color: $bracketsBlue;
      transition: 0.2s;
      cursor: pointer;
      margin-bottom: 1rem;
      text-align: end;
      @media screen and (max-width: 1000px) {
        text-align: center;
      }

      &:hover {
        color: $WBlue;
      }
    }
  }
}
#formContainer {
  margin-top: 150px;
  @media screen and (max-width: 1000px) {
    margin-top: 0;
  }
}
#contactLegend {
  @media screen and (max-width: 1000px) {
    display: none;
  }
}
