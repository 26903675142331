nav {
  display: flex;
  padding-top: 8rem;
  justify-content: center;

  ul {
    display: flex;
    flex-direction: column;
    @media screen and (max-width: $breakPoint) {
      flex-direction: row;
      border: 0.3px solid $bracketsBlue;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
    }
    a {
      text-decoration: none;
      padding: 10px;

      li {
        color: $bracketsBlue;
        transition: 0.2s;
        cursor: pointer;

        &:hover {
          color: $WBlue;
        }
      }
    }

    .nav-active {
      position: relative;

      &::after {
        content: "";
        height: 4px;
        width: 0;
        background: $bracketsBlue;
        position: absolute;
        border-radius: 10px;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
        -webkit-animation: anim 0.3s ease forwards;
        animation: anim 0.6s ease forwards;

        @-webkit-keyframes anim {
          to {
            width: 100%;
          }
        }
        @keyframes anim {
          to {
            width: 100%;
          }
        }
        @media screen and (max-width: $breakPoint) {
          height: 2px;
          margin-bottom: 5px;
        }
      }
    }
  }
}
