#bgImg {
  position: absolute;
  left: 50%;
  top: 20%;
  width: 40vw;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  @media screen and (min-width: 1600px) {
    width: 25vw;
  }
  @media screen and (min-width: $breakPoint) and (max-width: 1599px) {
    width: 30vw;
  }
}
#imgDiv {
  z-index: 10;
}
